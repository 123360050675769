import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import Resume from "../assets/img/CV.pdf";
export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  const clearFields = () => {
    setEmail("");
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={8}>
            <h3>
              Contect to me for make your dreem web app<br></br> Always redy to
              provide services
            </h3>
          </Col>
          <Col md={6} xl={4}>
            <form onSubmit={handleSubmit}>
              <div className="new-email-bx">
                <input
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly
                  placeholder="👉👉👉"
                />

                <a href={Resume} download>
                  <button type="button" className="button s-button">Download CV</button>
                </a>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
